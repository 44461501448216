import clsx from 'clsx'
import React from 'react'

import * as styles from './styles.module.scss'
import methodology from '../../images/solutions/methodology.png'

interface MethodologySectionProps {
  items: itemList[]
  descriptionSection?: string
}

export interface itemList {
  count: number
  title: string
  description: React.ReactNode
}

export const MethodologySection: React.FC<MethodologySectionProps> = ({
  items,
  descriptionSection,
}) => {
  return (
    <div className={styles.methodology}>
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className={styles.title}>
            <h2 className="mb-2">Metodologia Utilizada</h2>
            <p>
              {descriptionSection ? (
                descriptionSection
              ) : (
                <>
                  Conheça agora os 04 principais passos de nossa metologia, para
                  implementar a mudança na sua organização.
                </>
              )}
            </p>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className={styles.aboutImg}>
            <img src={methodology} alt="Highjump methodology" />
          </div>
        </div>
      </div>
      <div className="row align-items-center">
        <div className={clsx(['col-12 col-lg-12 pl-4', styles.boxMethodology])}>
          <ul className={styles.list}>
            {items &&
              items.map((item: itemList, key) => (
                <li key={key}>
                  <span>
                    <small>#</small>
                    {item.count}
                  </span>
                  <div>
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
